import { Routes } from '@angular/router';
import { FunctionSubmitFormComponent } from '../components/function-submit-form/function-submit-form.component'; // Adjust path as necessary

export const routes: Routes = [
  {
    path: 'submit-function',
    component: FunctionSubmitFormComponent
  },
  {
    path: '',
    redirectTo: '/submit-function',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/submit-function'
  }
];
