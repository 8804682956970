import { Component } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import 'codemirror/mode/python/python'; // Import Python mode for syntax highlighting
import { FunctionMetaDataDTO } from '../../DTOs/functionMetaDataDTO';

@Component({
  selector: 'app-function-submit-form',
  standalone: true,
  imports: [ReactiveFormsModule, CodemirrorModule],
  templateUrl: './function-submit-form.component.html',
  styleUrls: ['./function-submit-form.component.css']
})
export class FunctionSubmitFormComponent {
  functionForm: FormGroup;

  constructor(private fb: FormBuilder, private apiService: ApiService) {
    this.functionForm = this.fb.group({
      functionCode: ['', Validators.required],
    });
  }


  onSubmit() {
    if (this.functionForm.valid) {
      const apiKey = 'your_api_key';
      const content = this.functionForm.value.functionCode;

      this.apiService.analyzeFunction(apiKey, content).subscribe(
        (response: FunctionMetaDataDTO) => {
          alert('Function submitted successfully!\n' +
            `Function Name: ${response.name}\n` +
            `Function Args: ${response.args.length > 0 ? response.args.map(arg => JSON.stringify(arg)).join(', ') : 'No arguments'}\n` +
            `Time Complexity: ${response.time_complexity}\n` +
            `Creation Timestamp: ${response.analysis_timestamp}`
          );
        },
        (error) => {
          console.error('Error:', error);
          alert('Error: ' + error.error.detail);
        }
      );
    }
  }
}
