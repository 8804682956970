<form [formGroup]="functionForm" (ngSubmit)="onSubmit()">
  <label for="functionCode">Function Code:</label>
  <ngx-codemirror id="functionCode"
                  formControlName="functionCode"
                  [options]="{
                lineNumbers: true,
                theme: 'material',
                mode: 'python',
                tabSize: 2,
                indentUnit: 2,
                readOnly: false
  }"></ngx-codemirror>

  <button type="submit" [disabled]="functionForm.invalid">Submit</button>
</form>
