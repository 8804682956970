import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FunctionMetaDataDTO } from '../DTOs/functionMetaDataDTO';
import { ApiUrls } from '../models/api-service.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) { }

  analyzeFunction(apiKey: string, content: string): Observable<FunctionMetaDataDTO> {
    const params = { apiKey: apiKey };
    const body = { content: content };

    return this.http.post<FunctionMetaDataDTO>(ApiUrls.analyzeFunction, body, { params });
  }
}


